<template>
    <v-app>
        <div class="row">
            <div class="col-12">
                <div class="card">
                    <div class="card-header">
                        <div class="card-title pt-3 px-3 d-flex justify-content-between">
                            <div class="breadcrumb-left">
                                <h4> Manage hostel building</h4>
                                <div class="breadcrumb-sub-header">
                                    <router-link to="/dashboard">Dashboard</router-link>
                                    \ Hostel building
                                </div>
                            </div>
                            <div class="breadcrumb-right">
                                <div class="card-toolbar">
                                    <v-btn v-if="checkIsAccessible('hostel-building', 'create')" class="mt-4 btn btn-primary" style="color: #fff" @click="addHostelBuilding">
                                        <v-icon small elevation="2" outlined>fas fa-plus</v-icon>&nbsp;
                                        Add hostel building
                                    </v-btn>&nbsp;&nbsp;
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="card-body">
                        <div class="search-wrapper border-outline mb-5 px-3 py-5 d-sm-flex justify-content-start">
                            <div class="row">
                                <v-col cols="12" md="4">
                                    <v-autocomplete
                                        outlined
                                        dense
                                        v-model="search.hostel_id"
                                        :items="hostels"
                                        item-text="title"
                                        item-value="id"
                                        label="Search by hostel name"
                                        v-on:keyup.enter="getHostelBuildings()"
                                        clearable
                                    ></v-autocomplete>
                                </v-col>

                                <v-col cols="12" md="4">
                                    <v-text-field 
                                        class="form-control" 
                                        v-model="search.title"
                                        label="Search by title" 
                                        outlined 
                                        clearable
                                        v-on:keyup.enter="getHostelBuildings()" dense>
                                    </v-text-field>
                                </v-col>
                                
                                <v-col cols="12" md="4">
                                    <v-btn :loading="loading" @click.prevent="searchHostelBuildings()"
                                        class="btn btn-primary w-35 float-right" dark>
                                        <v-icon small elevation="2" outlined>fas fa-search</v-icon>&nbsp;
                                        Search
                                    </v-btn>
                                </v-col>
                            </div>
                        </div>
                        <div class="">
                            <v-skeleton-loader v-if="loading" type="table-thead">
                            </v-skeleton-loader>

                            <v-skeleton-loader v-if="loading" type="table-row-divider@25">
                            </v-skeleton-loader>
                            <table class="table" v-if="!loading">
                                <thead>
                                    <tr class="px-3">
                                        <th class="px-3">Title</th>
                                        <th class="px-3">Hostel</th>
                                        <th class="px-3">More information</th>
                                        <th class="px-3">Status</th>
                                        <th class="pr-3 text-center">Action</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr v-show="hostelBuildings.length > 0" v-for="(item, index) of hostelBuildings" :key="index">
                                        <td class="px-3">
                                            {{ item.title }}
                                        </td>
                                        <td class="px-3">
                                            {{ item.hostel_name ? item.hostel_name : 'NA' }}
                                        </td>
                                        <td class="px-3">
                                            <div>
                                                <strong> Number of floors:</strong> {{ item.no_of_floors ? item.no_of_floors : '-' }}                                                
                                            </div>
                                            <div class="mt-2">
                                                <strong> Number of rooms:</strong> {{ item.no_of_rooms ? item.no_of_rooms : '-' }}                                                
                                            </div>
                                            <div class="mt-2">
                                                <strong> Total capacity:</strong> {{ item.total_capacity ? item.total_capacity : '-' }}                                                
                                            </div>
                                        </td>
                                        <td class="px-3">
                                            <span 
                                                class="badge text-lg "
                                                v-bind:class="{ 'badge-success': item.is_active, 'badge-danger': !item.is_active }">
                                                {{ item.is_active ? 'Active' : 'Inactive' }}
                                            </span>
                                        </td>
                                        <td class="pr-0 text-center">
                                            <b-dropdown size="sm" variant="link"
                                                toggle-class="custom-v-dropdown btn btn-clean btn-hover-light-primary btn-sm btn-icon"
                                                no-caret right no-flip>
                                                <template v-slot:button-content>
                                                    <slot>
                                                        <span>
                                                            <i class="flaticon-more-1"></i>
                                                        </span>
                                                    </slot>
                                                </template>
                                                <!--begin::Navigation-->
                                                <div class="navi navi-hover ">
                                                    <b-dropdown-text tag="div" class="navi-item" v-if="checkIsAccessible('hostel-building', 'edit')">
                                                        <a href="#" class="navi-link" @click="editHostelBuilding(item.id)">
                                                            <span class="navi-icon">
                                                                <i class="flaticon-edit"></i>
                                                            </span>
                                                            <span class="navi-text"> Edit </span>
                                                        </a>
                                                    </b-dropdown-text>
                                                    <b-dropdown-text tag="div" class="navi-item" v-if="checkIsAccessible('hostel-building', 'delete')">
                                                        <a href="#" class="navi-link" @click="deleteHostelBuilding(item.id)">
                                                            <span class="navi-icon">
                                                                <i class="flaticon-delete"></i>
                                                            </span>
                                                            <span class="navi-text"> Delete </span>
                                                        </a>
                                                    </b-dropdown-text>
                                                </div>
                                            </b-dropdown>
                                        </td>
                                    </tr>
                                    <tr v-if="hostelBuildings.length == 0">
                                        <td class="text-center  px-3" colspan="7"> <strong>No data available to display.</strong></td>
                                    </tr>
                                </tbody>
                            </table>
                            <b-pagination 
                                v-show="hostelBuildings.length > 0" 
                                @input="getHostelBuildings" 
                                class="pull-right mt-7" 
                                v-model="page"
                                :total-rows="total" 
                                :per-page="perPage" 
                                first-number 
                                last-number
                            ></b-pagination>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <create-and-update ref="hostel-building" @refresh="getHostelBuildings"></create-and-update>
    </v-app>
</template>

<script>
import HostelBuildingService from "@/core/services/hostel/hostel-building/HostelBuildingService";
import CreateAndUpdate from "./CreateAndUpdate.vue";
import HostelService from "@/core/services/hostel/HostelService";

const hostelBuilding = new HostelBuildingService();
const hostel = new HostelService();

export default {
    components: {
        CreateAndUpdate
    },
    data() {
        return {
            search: {
                hostel_id: null,
                title: ''
            },
            loading: false,
            hostelBuildings: [],
            page: null,
            perPage: null,
            total: null,
            hostels: []
        }
    },
    methods: {
        searchHostelBuildings(){
            this.page = 1;
            this.getHostelBuildings();
        },  
        getHostelBuildings() {
            this.loading = true;
            hostelBuilding
                .paginate(this.search, this.page)
                .then(response => {
                    this.hostelBuildings = response.data.data;
                    this.page = response.data.meta.current_page;
                    this.total = response.data.meta.total;
                    this.perPage = response.data.meta.per_page;
                    this.loading = false;
                })
                .catch(error => {
                    this.loading = false;
                });
        },
        addHostelBuilding() {
            this.$refs["hostel-building"].showModal();
        },
        editHostelBuilding(id) {
            this.$refs["hostel-building"].showModal(id);
        },
        deleteHostelBuilding(hostelBuildingId){
            this.$confirm({
                message: `Are you sure you want to delete?`,
                button: {
                    no: "No",
                    yes: "Yes"
                },
                /**
                 * Callback Function
                 * @param {Boolean} confirm
                 */
                callback: confirm => {
                    if (confirm) {
                        hostelBuilding
                        .delete(hostelBuildingId)
                        .then(response => {
                            this.$snotify.success('Successfully deleted')
                            this.getHostelBuildings();
                        })
                        .catch(error => {
                        });
                    }
                }
            });
        },
        getHostels(){
            hostel
            .all()
            .then((res) => {
                this.hostels = res.data.hostels;
            })
            .catch(err => {

            });
        }
    },
    mounted() {
        this.getHostelBuildings();
        this.getHostels();
    }
}
</script>