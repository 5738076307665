<template>
    <v-app>
        <v-dialog v-model="dialog" max-width="800">
            <v-card>
                <v-toolbar dark>
                    <v-card-title class="headline">
                        {{ `${edit ? 'Update' : "Add"} hostel building` }}
                        <hr>
                    </v-card-title>

                    <v-spacer></v-spacer>
                    <v-toolbar-items>
                        <v-spacer></v-spacer>
                        <v-btn icon @click="cancelForm">
                            <i class="fas fa-times"></i>
                        </v-btn>
                    </v-toolbar-items>
                </v-toolbar>

                <v-card-text>
                    <v-row>
                        <v-col cols="12">
                            <v-text-field
                                v-model="hostelBuilding.title"
                                outlined
                                dense
                                :error="$v.hostelBuilding.title.$error"
                            >
                                <template v-slot:label>
                                    Title <span class="text-danger">*</span>
                                </template>
                            </v-text-field>
                            <span class="text-danger" v-if="$v.hostelBuilding.title.$error">This information is required</span>
                        </v-col>

                        <v-col cols="12">
                            <v-autocomplete
                                v-model="hostelBuilding.hostel_id"
                                outlined
                                dense
                                :items="hostels"
                                item-text="title"
                                item-value="id"
                                :error="$v.hostelBuilding.hostel_id.$error"
                            >
                                <template v-slot:label>
                                    Hostel <span class="text-danger">*</span>
                                </template>
                            </v-autocomplete>
                            <span class="text-danger" v-if="$v.hostelBuilding.hostel_id.$error">This information is required</span>
                        </v-col>

                        <v-col cols="12">
                            <label>
                                Description <span class="text-danger"></span>
                            </label>
                            <ckeditor v-model="hostelBuilding.description" :config="editorConfig"></ckeditor>
                            <span class="text-danger" v-if="$v.hostelBuilding.description.$error">This information is required</span>
                        </v-col>

                        <v-col cols="12" md="4" class="mt-3">
                            <v-text-field
                                v-model="hostelBuilding.no_of_floors"
                                outlined
                                type="number"
                                dense
                                :error="$v.hostelBuilding.no_of_floors.$error"
                            >
                                <template v-slot:label>
                                    Number of floors<span class="text-danger">*</span>
                                </template>
                            </v-text-field>
                            <span class="text-danger" v-if="$v.hostelBuilding.no_of_floors.$error">This information is required</span>
                        </v-col>

                        <v-col cols="12" md="4" class="mt-3">
                            <v-text-field
                                v-model="hostelBuilding.no_of_rooms"
                                outlined
                                type="number"
                                dense
                                :error="$v.hostelBuilding.no_of_rooms.$error"
                            >
                                <template v-slot:label>
                                    Number of rooms<span class="text-danger">*</span>
                                </template>
                            </v-text-field>
                            <span class="text-danger" v-if="$v.hostelBuilding.no_of_rooms.$error">This information is required</span>
                        </v-col>

                        <v-col cols="12" md="4" class="mt-3">
                            <v-text-field
                                v-model="hostelBuilding.total_capacity"
                                outlined
                                type="number"
                                dense
                                :error="$v.hostelBuilding.total_capacity.$error"
                            >
                                <template v-slot:label>
                                    Total capacity<span class="text-danger">*</span>
                                </template>
                            </v-text-field>
                            <span class="text-danger" v-if="$v.hostelBuilding.total_capacity.$error">This information is required</span>
                        </v-col>

                        <v-col cols="12">
                            <label>
                                Status
                            </label>
                            <v-switch v-model="hostelBuilding.is_active" :label="hostelBuilding.is_active ? 'Active' : 'Inactive'"></v-switch>
                            <span class="text-danger" v-if="$v.hostelBuilding.is_active.$error">This information is required</span>
                        </v-col>

                        <v-col cols="12">
                            <div class="text-right float-right">
                                <v-btn
                                    depressed
                                    @click="cancelForm"
                                    class="text-gray btn btn-standard
                                ">
                                    Cancel
                                </v-btn>
                                <!-- <v-btn
                                    v-if="checkIsAccessible('gate-pass', 'create')"
                                    depressed
                                    :loading="isBusy"
                                    @click="createOrUpdate"
                                    class="text-white ml-2 btn btn-primary"
                                >
                                    Save
                                </v-btn> -->
                                <v-btn
                                    depressed
                                    :loading="isBusy"
                                    @click="createOrUpdate"
                                    class="text-white ml-2 btn btn-primary"
                                    v-if="checkIsAccessible('hostel-building', 'create') && checkIsAccessible('hostel-building', 'edit')"
                                >
                                  {{ edit ? "Update" : "Save" }}
                                </v-btn>
                            </div>
                        </v-col>
                    </v-row>

                </v-card-text>
            </v-card>
        </v-dialog>
    </v-app>

</template>
<script>
import { required } from "vuelidate/lib/validators";
import HostelBuildingService from "@/core/services/hostel/hostel-building/HostelBuildingService";
import HostelService from "@/core/services/hostel/HostelService";

const hostelBuilding = new HostelBuildingService();
const hostel = new HostelService();

export default {
    validations: {
        hostelBuilding: {
            hostel_id: {required},
            title: { required },
            description: {},
            no_of_floors: {required},
            no_of_rooms: {required},
            total_capacity: {required},
            is_active: {}
        }
    },
    data() {
        return {
            dialog: false,
            edit: false,
            isBusy: false,
            hostelBuilding: {
                hostel_id: null,
                title: '',
                description: '',
                no_of_floors: null,
                no_of_rooms: null,
                total_capacity: null,
                is_active: true,
            },
            hostels: [],
            editorConfig: {
                versionCheck: false,
                toolbar: [['Bold', 'Italic', 'Underline', 'Strike', 'RemoveFormat', 'Link', 'NumberedList', 'BulletedList']]
            },
        };
    },
    methods: {
        showModal(id = null) {
            this.getHostels();
            if (id) {
                this.edit = true
                this.getHostelBuilding(id)
            }
            this.dialog = true;
        },
        hideModal() {
            this.dialog = false;
        },
        cancelForm(){
            this.resetForm();
            this.hideModal();
        },
        getHostelBuilding(id) {
            hostelBuilding
            .show(id)
            .then(response => {
                this.hostelBuilding = response.data.hostelBuilding;
            })
            .catch(err => {
            })
            .finally(() => {
            })
        },
        createOrUpdate() {
            this.$v.hostelBuilding.$touch();
            if (this.$v.hostelBuilding.$error) {
                setTimeout(() => {
                    this.$v.hostelBuilding.$reset();
                }, 3000);
            } else {
                if (this.edit) {
                    this.updateHostelBuilding();
                } else {
                    this.createHostelBuilding(this.hostelBuilding);
                }
            }
        },
        updateHostelBuilding() {
            this.isBusy = true;
            hostelBuilding
            .update(this.hostelBuilding.id, this.hostelBuilding)
            .then(response => {
                this.isBusy = false;
                this.$snotify.success("Information updated");
                this.resetForm();
                this.hideModal();
                this.$emit("refresh");
            })
            .catch(error => {
                this.isBusy = false;
                this.$snotify.error("Something Went Wrong");
            });
        },
        createHostelBuilding(fd) {
            this.isBusy = true;
            hostelBuilding
            .store(this.hostelBuilding)
            .then((response) => {
                this.isBusy = false;
                this.resetForm();
                this.$snotify.success("Information added");
                this.hideModal();
                this.$emit("refresh");
            })
            .catch(error => {
                this.isBusy = false;
                this.$snotify.error("Something Went Wrong");
            });
        },
        resetForm() {
            this.$v.hostelBuilding.$reset();
            this.edit = false;
            this.hostelBuilding = {
                title: '',
                is_active: true
            };
        },
        getHostels(){
            hostel
            .all()
            .then((res) => {
                this.hostels = res.data.hostels;
            })
            .catch(err => {

            });
        }
    }
}
</script>
